import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProfileItem = ({
  profile
  // : {
  //   user: { _id, name, avatar },
  //   status,
  //   company,
  //   location,
  //   skills
  // }
}) => {
  if (!profile.user) {
    return null; // Or render an alternative UI for missing data
  }
  // console.log(">>> ProfilrItem:", {  _id: profile?.user?._id, name: profile?.user?.name, avatar: profile?.user?.avatar })
  return (
    profile.user && <div className='profile bg-light'>
      <img src={profile?.user?.avatar} alt='' className='round-img' />
      <div>
        <h2>{profile?.user?.name}</h2>
        <p>
          {profile?.user?.status} {profile?.user?.company && <span> at {profile?.user?.company}</span>}
        </p>
        <p className='my-1'>{profile?.user?.location && <span>{profile?.user?.location}</span>}</p>
        <Link to={`/profile/${profile?.user?._id}`} className='btn btn-primary'>
          View Profile
        </Link>
      </div>
      <ul>
        {profile?.user?.skills?.slice(0, 4).map((skill, index) => (
          <li key={index} className='text-primary'>
            <i className='fas fa-check' /> {skill}
          </li>
        ))}
      </ul>
    </div>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.shape({
    user: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      avatar: PropTypes.string
    }),
    status: PropTypes.string,
    company: PropTypes.string,
    location: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default ProfileItem;
